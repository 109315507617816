import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './app/reportWebVitals';
import {store} from "./app/store";
import {LinearProgress} from "@mui/material";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {App} from "./app/App";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <Suspense fallback={<LinearProgress/>}>
                <App/>
            </Suspense>
        </BrowserRouter>
    </Provider>
);

reportWebVitals();
