import {ImeiProps} from "./types";
import {DateTime} from "luxon";


export class Imei {
    ip: string
    port: number
    protocol: string
    newimei: string
    timedelta: number
    dtfrom: DateTime
    imei: string

    constructor(props?: ImeiProps) {
        this.ip = props?.ip ?? ''
        this.port = props?.port ?? 0
        this.protocol = props?.protocol ?? ''
        this.newimei = props?.newimei ?? ''
        this.timedelta = props?.timedelta ?? 0
        this.dtfrom = props?.dtfrom ?? DateTime.fromISO("2000-01-01");
        this.imei = props?.imei ?? ''
    }
}