import {
    BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta
} from "@reduxjs/toolkit/query";
import {fetchBaseQuery} from "@reduxjs/toolkit/query/react";

export const baseQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    {},
    FetchBaseQueryMeta
> = fetchBaseQuery({})
