import {baseApi, IMEI, IMEI_DETAIL} from "../api";
import {
    AddImeiBody, addImeiResponse, DeleteImeiBody, DeleteImeiResponse, ImeisDetailsParams, ImeiDetailsResponse,
    ImeisParams, ImeisResponse
} from "./types";

export const imeisApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getImeis: build.query<ImeisResponse, ImeisParams>({
            query: ({sid}) => ({
                url: 'api/imeis',
                params: {sid}
            }),
            providesTags: [{type: IMEI, id: 'LIST'}]
        }),
        getImeiDetails: build.query<ImeiDetailsResponse, ImeisDetailsParams>({
            query: ({sid, imei, ip, port, timestamp}) => ({
                url: `api/stat?sid=${sid}&imei=${imei}&ip=${ip}&port=${port}&${timestamp}`
            }),
            providesTags: [{type: IMEI_DETAIL, id: 'LIST'}]
        }),
        addImei: build.mutation<addImeiResponse, AddImeiBody>({
            query: ({imei, ip, port,newimei, dtfrom,timedelta, protocol,  sid}) => ({
                url: `api/imeis`,
                method: 'POST',
                params: {sid},
                body: {imei, ip, port, newimei, dtfrom, protocol, timedelta,},
            }),
            invalidatesTags: [{type: IMEI}],
        }),
        deleteImei: build.mutation<DeleteImeiResponse, DeleteImeiBody>({
            query: ({imei, ip, port, newimei, sid}) => ({
                url: `api/imeis`,
                method: 'DELETE',
                params: {sid},
                body: {imei, ip, port, newimei},
            }),
            invalidatesTags: [{type: IMEI}],
        }),
    }),
})

export const {
    useGetImeisQuery,
    useGetImeiDetailsQuery,
    useAddImeiMutation,
    useDeleteImeiMutation,
} = imeisApi