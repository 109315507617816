import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";
import {core} from "../../entity/core";
import {DateTime} from "luxon";
import {imeisApi} from "../handleImeis/imeisApi";
import {ImeiProps} from "../../entity/imeis/imei";
import {Order} from "../../entity/imeis/imeis";

export type HandleTableState = {
    imeiIndexes: string[]
    rowsOnPage: number
    startIndex: number
    endIndex: number
    isRefetch: boolean
    searchFilter: FilterProps[]
    sortedOrder: SortProps[]
}

const initialState: HandleTableState = {
    imeiIndexes: [],
    rowsOnPage: 25,
    startIndex: 0,
    endIndex: 25,
    isRefetch: false,
    searchFilter: [],
    sortedOrder: [{key: "imei", order: Order.DESC}]
}

const DTFORMAT = 'yyyy-MM-dd HH:mm:ss'

export const handleTableSlice = createSlice({
    name: 'handleTable', initialState, reducers: {
        makeRefetch: (state, {payload: {isRefetch}}: PayloadAction<{
            isRefetch: boolean
        }>) => {
            state.isRefetch = isRefetch
        },

        setSearchFilterText: (state,
                              {payload: filters}: PayloadAction<FilterProps[]>) => {
            filters.forEach(({key, text}) => {
                const searchMarker = state.searchFilter.find(item => item.key === key)
                if (searchMarker) {
                    searchMarker.text = text
                } else {
                    if (text !== '') {
                        state.searchFilter.push({key, text})
                    }
                }
            })

            state.searchFilter = state.searchFilter.filter(i => i.text !== '')
            state.imeiIndexes = filterOrder({
                filters: state.searchFilter, orders: state.sortedOrder
            })
            state.startIndex = 0
            state.endIndex = Math.min(state.rowsOnPage, state.imeiIndexes.length)
        },

        setSortOrder: (state, {payload: {filterCell}}: PayloadAction<{ filterCell: imeiKeys }>) => {
            const sortMarker = state.sortedOrder.find(item => item.key === filterCell)

            if (sortMarker && sortMarker.order === 'asc') sortMarker.order = Order.NULL
            if (sortMarker && sortMarker.order === 'desc') sortMarker.order = Order.ASC
            if (!sortMarker) state.sortedOrder.unshift({key: filterCell, order: Order.DESC})

            state.sortedOrder = state.sortedOrder.filter(i => i.order !== '')
            state.imeiIndexes = filterOrder({
                filters: state.searchFilter, orders: state.sortedOrder
            })
            state.startIndex = 0
            state.endIndex = Math.min(state.rowsOnPage, state.imeiIndexes.length)
        },
        dropFilters: (state, payload) => {
            state.searchFilter = []
            state.sortedOrder = [{key: "imei", order: Order.DESC}]

            state.imeiIndexes = filterOrder({
                filters: state.searchFilter, orders: state.sortedOrder
            })
            state.startIndex = 0
            state.endIndex = Math.min(state.rowsOnPage, state.imeiIndexes.length)
        }
    },

    extraReducers: (builder) => {
        builder
            .addMatcher(imeisApi.endpoints.getImeis.matchFulfilled,
                (state, {payload: {data}}) => {
                    core.imeis.clear()
                    data.forEach(obj => core.imeis.append({
                        ip: '' + obj?.ip ?? '', port: +obj?.port ?? 0, protocol: '' +
                            obj?.protocol ?? '',
                        newimei: '' + obj?.newimei ??
                            '',
                        timedelta: +obj?.timedelta ??
                            0, dtfrom: DateTime.fromFormat(
                            '' + obj?.dtfrom ?? '2000-01-01', DTFORMAT, {zone: 'UTC'}), imei: '' +
                            obj?.imei ?? ''
                    }))
                    state.imeiIndexes = filterOrder({
                        filters: state.searchFilter, orders: state.sortedOrder
                    })
                    state.startIndex = 0
                    state.endIndex = Math.min(state.rowsOnPage, state.imeiIndexes.length)
                })
            .addMatcher(imeisApi.endpoints.addImei.matchFulfilled,
                (state, {payload: {data}}) => {
                    state.isRefetch = true
                })
    }
})

export type imeiKeys = keyof ImeiProps

export type FilterProps = {
    key: imeiKeys
    text: string
};

export type SortProps = {
    key: imeiKeys
    order: Order;
};

const getStr = (idkey: string, field: keyof ImeiProps): string => {
    if (field === 'dtfrom') {
        return core.imeis.imeis[idkey][field].toFormat(DTFORMAT)
    }
    if (field === 'port' || field === 'timedelta') {
        return '' + core.imeis.imeis[idkey][field]
    }
    return core.imeis.imeis[idkey][field]
}

const filterOrder = ({filters, orders}: {
    filters: FilterProps[], orders: SortProps[]
}) => {
    const keys = Object.keys(core.imeis.imeis);
    const tmp: string[] = filters
        .reduce((acc, {key, text}) => {
            return acc.filter((idimei: string) => getStr(idimei, key)
                .includes(text))
        }, keys)
    return orders.reduce((acc, {key, order}) => {
        const t = (order === 'asc') ? 1 : 0
        return tmp.sort((a, b) => getStr(a, key) > getStr(b, key) ? t : 1 - t)
    }, tmp)
}

export const selectImeisState = (state: RootState) => state.handleTable
export const {
    makeRefetch, setSearchFilterText, setSortOrder, dropFilters
} = handleTableSlice.actions


