import {baseApi, USER} from "../api";
import {LoginResponse, UserBody} from "./types";

export const authorizationApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        login: build.mutation<LoginResponse, Partial<UserBody>>({
            query: (body) => ({
                url: `login`,
                method: 'POST',
                body,
            }),
            invalidatesTags: [{type: USER}],
        }),
    }),
})

export const {useLoginMutation} = authorizationApi
