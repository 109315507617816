import React, {lazy} from "react";
import {Route, Routes} from "react-router-dom";
import {Header} from "../widget/header";

export enum PATH {
    MAINPAGE = '/',
    LOGOUT = '/logout'
}

const MainPageLazy = lazy(() => import('./MainPage'))
const LogoutPage = lazy(() => import('./LogoutPage'))

export const Routing = () => {
    return (
        <Routes>
            <Route path={`${PATH.MAINPAGE}`} element={<MainPageLazy/>}/>
            <Route path={`${PATH.LOGOUT}`} element={<LogoutPage/>}/>
        </Routes>
    );
}