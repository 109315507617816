import {UserProps} from "./types";
import {core} from "../core";

interface Dict {
    [key: string]: string | number
}

const USER = 'user'
const SID = 'sid'

export class User {
    user: string
    sid: string

    constructor(props?: UserProps) {
        this.user = props?.user ?? localStorage.getItem(USER) ?? ''
        this.sid = props?.sid ?? localStorage.getItem(SID) ?? ''
    }

    setCreds({user, sid}: CredProps) {
        this.user = user ?? ''
        this.sid = sid ?? ''
        localStorage.setItem(USER, this.user)
        localStorage.setItem(SID, this.sid)
    }
}

type CredProps = {
    user?: string
    sid?: string
}