import React from 'react';
import './App.css';
import {Routing} from "../page";

export const App = () => {
    return (
        <Routing/>
    );
}

