import {createSlice, isAnyOf, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";
import {authorizationApi} from "../authorize/authorizationApi";
import {imeisApi} from "../handleImeis/imeisApi";

type AuthState = {
    isLoading: boolean
    error: boolean
    message: string
}

const initialState: AuthState = {
    isLoading: false,
    error: false,
    message: ''
}

export const feedbackSlice = createSlice({
    name: 'feedback',
    initialState,
    reducers: {
        showLoading: (
            state,
            {payload: {isLoading}}: PayloadAction<{ isLoading: boolean }>
        ) => {
            state.isLoading = isLoading
        },
        showError: (
            state,
            {payload}: PayloadAction<{ error: boolean, message?: string }>
        ) => {
            state.error = payload.error
            state.message = payload?.message || state.message
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                isAnyOf(
                    authorizationApi.endpoints.login.matchPending,
                    imeisApi.endpoints.getImeis.matchPending,
                    imeisApi.endpoints.getImeiDetails.matchPending,
                    imeisApi.endpoints.addImei.matchPending,
                ), (state, {payload}) => {
                    state.isLoading = true
                }
            )
            .addMatcher(
                isAnyOf(
                    authorizationApi.endpoints.login.matchFulfilled,
                    imeisApi.endpoints.getImeis.matchFulfilled,
                    imeisApi.endpoints.getImeiDetails.matchFulfilled,
                    imeisApi.endpoints.addImei.matchFulfilled,
                ), (state, data) => {
                    state.isLoading = false
                }
            )
            .addMatcher(
                isAnyOf(
                    authorizationApi.endpoints.login.matchRejected,
                    imeisApi.endpoints.addImei.matchRejected,
                ), (state, {payload}) => {
                    state.isLoading = false
                    state.error = true
                }
            )
            .addMatcher(
                imeisApi.endpoints.getImeis.matchRejected,
                (state, {error, type}) => {
                    if (error.message === 'Rejected') state.error = true
                    state.isLoading = false
                }
            )
            .addMatcher(
                authorizationApi.endpoints.login.matchFulfilled,
                (state, data) => {
                    if (data.payload?.error) {
                        state.error = true
                        if (data.payload?.error === 'unknown user') {
                            state.message = 'Пользователь не найден.'
                        }
                    }
                    state.isLoading = false
                })
    }
})

export const selectFeedback = (state: RootState) => state.feedback

export const {
    showLoading,
    showError
} = feedbackSlice.actions