import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {core} from "../../entity/core";
import {imeisApi} from "./imeisApi";
import {DateTime} from "luxon";
import {RootState} from "../../app/store";
import {Order} from "../../entity/imeis/imeis";
import {PKProps} from "../../entity/imeis/pk/types";

export type FilterProps = {
    key: keyof PKProps
    text: string
};

export type SortProps = {
    key: keyof PKProps
    order: Order;
};


type ImeisDetailsState = {
    pksIndexes: string[]
    searchFilter: FilterProps[]
    sortedOrder: SortProps[]
}

const initialState: ImeisDetailsState = {
    pksIndexes: [],
    searchFilter: [],
    sortedOrder: [{key: "dt", order: Order.DESC}]
}

const DTFORMAT = 'yyyy-MM-dd HH:mm:ss'

export const imeisDetailsSlice = createSlice({
    name: 'imeisDetails',
    initialState,
    reducers: {
        clearPks: (state, payload) => {
            state.pksIndexes = []
        },
        setSortOrder: (state, {payload: {filterCell}}: PayloadAction<{ filterCell: keyof PKProps }>) => {
            const sortMarker = state.sortedOrder.find(item => item.key === filterCell)

            if (sortMarker && sortMarker.order === 'asc') sortMarker.order = Order.NULL
            if (sortMarker && sortMarker.order === 'desc') sortMarker.order = Order.ASC
            if (!sortMarker) state.sortedOrder.unshift({key: filterCell, order: Order.DESC})

            state.sortedOrder = state.sortedOrder.filter(i => i.order !== '')
            state.pksIndexes = filterOrder({
                filters: state.searchFilter, orders: state.sortedOrder
            })
        },
        dropDetailsFilters: (state, payload) => {
            state.sortedOrder = [{key: "dt", order: Order.DESC}]

            state.pksIndexes = filterOrder({
                filters: state.searchFilter, orders: state.sortedOrder
            })
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                imeisApi.endpoints.getImeiDetails.matchFulfilled,
                (state, {payload: {data}}) => {
                    core.imeis.clearPKS()
                    data.forEach(item => {
                        core.imeis.appendPK({
                            pk: +item?.pk ?? 0,
                            dtdev: DateTime.fromFormat('' + item?.dtdev ?? '2000-01-01', DTFORMAT, {zone: 'UTC'}),
                            dt: DateTime.fromFormat('' + item?.dt ?? '2000-01-01', DTFORMAT, {zone: 'UTC'}),
                        })
                    })
                    state.pksIndexes = filterOrder({
                        filters: state.searchFilter, orders: state.sortedOrder
                    })
                }
            )
    }
})

const getStr = (idkey: string, field: keyof PKProps): string => {
    if (field === "pk") {
        return ""+core.imeis.pks[idkey][field]
    }
    return core.imeis.pks[idkey][field].toFormat(DTFORMAT)
}

const filterOrder = ({filters, orders}: {
    filters: FilterProps[], orders: SortProps[]
}) => {
    const keys = Object.keys(core.imeis.pks);
    const tmp: string[] = filters
        .reduce((acc, {key, text}) => {
            return acc.filter((idimei: string) => getStr(idimei, key)
                .includes(text))
        }, keys)
    return orders.reduce((acc, {key, order}) => {
        const t = (order === 'asc') ? 1 : 0
        return tmp.sort((a, b) => getStr(a, key) > getStr(b, key) ? t : 1 - t)
    }, tmp)
}

export const selectPKs = (state: RootState) => state.imeisDetails

export const {clearPks, setSortOrder, dropDetailsFilters} = imeisDetailsSlice.actions
