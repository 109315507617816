import {ImeiProps} from "./imei";
import {Imei} from "./imei/imei";
import {PK} from "./pk/pk";
import {PKProps} from "./pk/types";

export const enum Order {
    ASC = 'asc',
    DESC = 'desc',
    NULL = ''
}

type ImeisType = {
    [key: string]: ImeiProps
}

type PKType = {
    [key: string]: PKProps
}

export interface ImeisProps {
}

export class Imeis {
    imeis: ImeisType
    idImeis: number
    pks: PKType
    idPks: number

    constructor() {
        this.imeis = {}
        this.idImeis = -1
        this.pks = {}
        this.idPks = -1
    }

    append(value: ImeiProps) {
        this.idImeis++
        this.imeis[this.idImeis] = new Imei(value)
    }

    appendPK(value: PKProps) {
        this.idPks++
        this.pks[this.idPks] = new PK(value)
    }

    clear() {
        this.imeis = {}
        this.idImeis = -1
        this.pks = {}
        this.idPks = -1
    }

    clearPKS() {
        this.pks = {}
        this.idPks = -1
    }
}
