import {createSlice, isAnyOf, PayloadAction} from "@reduxjs/toolkit";
import {core} from "../../entity/core";
import {RootState} from "../../app/store";
import {authorizationApi} from "./authorizationApi";
import {imeisApi} from "../handleImeis/imeisApi";

type AuthState = {
    isLogged: boolean
}

const initialState: AuthState = {
    isLogged: !!core.user.sid
}

export const authorizationSlice = createSlice({
    name: 'authorization',
    initialState,
    reducers: {
        login: (
            state,
            {payload: {user, sid}}: PayloadAction<{
                user?: string
                sid?: string
            }>
        ) => {
            //закинуть в fulfilled extrareducer
            core.user.setCreds({user, sid})
            state.isLogged = !!sid
        },
        logout: (
            state
        ) => {
            core.user.setCreds({user: '', sid: ''})
            state.isLogged = false
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                authorizationApi.endpoints.login.matchFulfilled
                , (state, data) => {
                    if (data.payload?.sid) {
                        core.user.setCreds({user: data.meta.arg.originalArgs.user, sid: data.payload.sid})
                        state.isLogged = !!data.payload.sid
                    }
                }
            )
    }
})

export const selectAuthorization = (state: RootState) => state.authorization

export const {
    login,
    logout
} = authorizationSlice.actions

