import {PKProps} from "./types";
import {DateTime} from "luxon";

export class PK {
    pk: number
    dt: DateTime
    dtdev: DateTime

    constructor(props?: PKProps) {
        this.pk = props?.pk ?? 0
        this.dt = props?.dt ?? DateTime.fromISO("2000-01-01");
        this.dtdev = props?.dtdev ?? DateTime.fromISO("2000-01-01");
    }

}
