import {authorizationSlice} from "../feature/authorize";
import {handleTableSlice} from "../feature/handleTable";
import {imeisDetailsSlice} from "../feature/handleImeis";
import {feedbackSlice} from "../feature/showFeedback";
import {baseApi} from "../feature/api";
import {combineReducers} from "@reduxjs/toolkit";

export const rootReducer = combineReducers({
    [authorizationSlice.name]: authorizationSlice.reducer,
    [handleTableSlice.name]: handleTableSlice.reducer,
    [imeisDetailsSlice.name]: imeisDetailsSlice.reducer,
    [feedbackSlice.name]: feedbackSlice.reducer,
    [baseApi.reducerPath]: baseApi.reducer,
})